<template>
  <b-overlay
    variant="white"
    :show="passwordChanged == true"
    spinner-variant="primary"
    spinner-type="border"
    blur="0.1"
    opacity=".25"
    rounded="sm"
  >
    <div
      v-if="authorized == true"
      class="auth-wrapper auth-v1 px-2"
    >
      <div class="auth-inner py-2">
        <!-- Reset Password v1 -->
        <b-card
          v-if="passwordChanged == false"
          class="mb-0"
        >
          <!-- logo -->
          <b-link class="brand-logo">
            <b-img
              :src="require('@/assets/images/logo/logo_azul_Plenitas.png')"
              fluid
              style="height: 80px"
              alt="placeholder"
            />
          </b-link>

          <b-card-title
            class="mb-1"
            style="text-align: center;"
          >
            {{ $t('ResetPassword') }}
          </b-card-title>
          <b-card-text>
            <b-alert
              v-if="messageError"
              show
              variant="warning"
              class="p-1 Salert-message"
            >
              <strong class="message">{{ messageError }}</strong>
            </b-alert>
          </b-card-text>
          <!-- Password -->
          <validation-observer
            v-slot="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              class="p-1"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <validation-provider
                v-slot="validationContext"
                name="password"
                vid="password"
                rules="required|min:8|password"
              >
                <b-form-group
                  :label="$t('administrators.pass') + ' *'"
                >
                  <b-input-group>
                    <b-form-input
                      id="password"
                      v-model="userData.password"
                      autocomplete="new-password"
                      :type="passwordFieldType"
                      :placeholder="$t('administrators.pass')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    checkPassword(validationContext.failedRules)
                  }}</small>
                </b-form-group>
              </validation-provider>
              <!-- Password -->

              <validation-provider
                v-slot="validationContext"
                name="c-password"
                rules="required|confirmed:password"
              >
                <b-form-group
                  :label="$t('administrators.repass') + ' *'"
                  style="flex-direction: column;"
                >
                  <b-input-group>
                    <b-form-input
                      id="c-password"
                      v-model="userData.cpassword"
                      autocomplete="new-password"
                      :type="passwordFieldType"
                      :placeholder="$t('administrators.repass')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">
                    {{
                      checkConfirmPassword(validationContext.failedRules)
                    }}
                  </small>
                </b-form-group>
              </validation-provider>
              <!-- submit button -->
              <b-button
                block
                :disabled="disabled"
                class="mt-2"
                variant="success"
                type="submit"
              >
                {{ $t('ResetPasswordConfirm') }}
              </b-button>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('ResetPasswordBack') }}
            </b-link>
          </p>
        </b-card>
      <!-- /Reset Password v1 -->
      </div>
    </div>
    <template #overlay="{ spinnerVariant, spinnerType, spinnerSmall }">
      <div
        class="d-flex flex-column align-items-center justify-content-center"
        style="height: 100%;"
      >
        <b-spinner
          :variant="spinnerVariant"
          :type="spinnerType"
          :small="spinnerSmall"
          class="mb-2"
        />
        <h4>
          <feather-icon
            icon="CheckCircleIcon"
            class="text-success mb-25"
            size="20"
          />
          {{ $t('passwordAlreadyReset') }}
        </h4>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import {
  BCard,
  BCardTitle,
  BCardText,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
  BAlert,
  BForm,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, onMounted } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import useGraphJwt from '@/auth/jwt/useGraphJwt'
import useJwt from '@/auth/jwt/useJwt'

// eslint-disable-next-line import/no-cycle
import router from '@/router'

import {
  required, min, confirmed,
} from '@validations'

export default {
  components: {
    BAlert,
    BForm,
    BImg,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BOverlay,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const blankUserData = {
      password: '',
      cpassword: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    const toast = useToast()
    const messageError = ref('')
    const passwordChanged = ref(false)
    const disabled = ref(false)
    const authorized = ref(false)

    const logout = () => {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      document.cookie.split(';').forEach(cookie => {
        const [name] = cookie.split('=')
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      })
    }

    onMounted(() => {
      if (token === null) {
        router.push({ name: 'misc-error' })
      }
      useGraphJwt.login(process.env.VUE_APP_RECOVERY_CUSTOM_USER_USERNAME, process.env.VUE_APP_RECOVERY_CUSTOM_USER_PASSWORD).then(response => {
        const { errors, data } = response.data

        if (errors) {
          console.error(errors[0].message)
          this.errorMessage = errors[0].message
          return
        }

        useGraphJwt.setToken(data.tokenAuth.token)

        const query = `
            mutation($token: String!) {
              resetPasswordTokenValidation(token: $token) {
                result
              }
            }
          `
        axios.post('', {
          query,
          variables: {
            token,
          },
        }).then(validation => {
          const { result } = validation.data.data.resetPasswordTokenValidation
          if (result === false) {
            router.push({ name: 'misc-error' })
          } else {
            authorized.value = true
          }
          logout()
        }).catch(error => {
          console.error(error)
          logout()
          router.push({ name: 'misc-error' })
        })
      }).catch(err => {
        console.error(err)
        router.push({ name: 'misc-error' })
      })
    })

    const onSubmit = () => {
      if (passwordChanged.value === true) {
        return
      }
      disabled.value = true
      const data = userData.value
      // GraphQL mutation
      const query = `
        mutation($token: String!, $newPassword: String!) {
          resetPasswordMutation(token: $token, newPassword: $newPassword) {
            result
            error
          }
        }
      `
      axios.post('', {
        query,
        variables: {
          token,
          newPassword: data.password,
        },
      }).then(response => {
        setTimeout(() => {
          disabled.value = false
        }, 1000)
        const responseData = response.data.data.resetPasswordMutation
        if (responseData.result === true) {
          messageError.value = ''
          passwordChanged.value = true
          setTimeout(() => {
            router.push({ name: 'auth-login' }).catch(() => {})
          }, 3000) // Redirect after 3 seconds

          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('success'),
              icon: 'CheckIcon',
              variant: 'success',
              position: 'bottom-right',
            },
          })
        } else {
          console.error(responseData.error)
          messageError.value = i18n.t(responseData.error)
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('error'),
              text: i18n.t(responseData.error),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              position: 'bottom-right',
            },
          })
        }
      }).catch(error => {
        disabled.value = false
        console.error(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            position: 'bottom-right',
          },
        })
      })
    }

    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData,
    )
    return {
      messageError,
      passwordChanged,
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      disabled,
      authorized,
    }
  },
  data() {
    return {
      required,
      min,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    checkPassword(rule) {
      if (rule.required) {
        return this.$t('password')
      }
      if (rule.min) {
        return this.$t('longPassword')
      }
      if (rule.password) {
        return this.$t('contentPassword')
      }
      return ''
    },

    checkConfirmPassword(rule) {
      if (rule.required) {
        return this.$t('rePassword')
      }
      if (rule.confirmed) {
        return this.$t('confirmPassword')
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
